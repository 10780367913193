.searchPage {
    padding: 20px 50px;
}

.BoxSearch {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.BoxSearch input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkMark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: var(--colorWhite);
    border: 2px solid var(--colorGlobal);
    border-radius: 5px;
}

.BoxSearch:hover input ~ .checkMark {
    /* background-color: #ccc; */
}

.BoxSearch i {
    color: var(--colorSPrimary);
}

.BoxSearch input ~ .checkMark i {
    position: absolute;
    display: none;
    left: 2px;
    top: 2px;
    color: var(--colorWhite);
    animation: zoomI 0.2s ease-in-out;
}

.BoxSearch input:checked ~ .checkMark {
    background-color: var(--colorGlobal);
}

.BoxSearch input:checked ~ .checkMark i {
    display: inline-block;
}
/* Search Item */
.navFilter > h6 {
    font-size: 25px;
    padding-bottom: 16px;
}

.navFilter > h6 i {
    color: var(--colorGlobal);
    margin-right: 5px;
}

.filterItem h6 {
    padding-bottom: 10px;
    font-size: 20px;
}
.filterItem {
    margin-bottom: 10px;
}

.filterItem ul {
    padding-left: 15px;
}
.filterItem ul li {
    padding: 5px 0;
}

/* Mobile-Tablet */
.navFilterRes {
    display: none;
    position: relative;
}

.navFilterRes .textFilter {
    position: relative;
    z-index: 9999;
    font-size: 25px;
    padding-bottom: 16px;
}

.navFilterRes .textFilter i {
    color: var(--colorGlobal);
    margin-right: 5px;
}

.navFilterRes .filterContainer {
    /* display: none; */
    position: absolute;
    top: 40px;
    z-index: 9999;
    transform: translateX(calc(-100% - 50px));
    transition: all 0.5s;
}

.filterCheckInput {
    display: none;
}

/* Overlay filter */
.overlayFilter {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(117, 117, 117, 0.95);
    z-index: 1000;
    width: 100%;
    height: 100%;
}

.filterCheckInput:checked ~ .overlayFilter {
    display: block;
    /* color: red; */
}

.filterCheckInput:checked ~ .filterContainer {
    transform: translateX(0);
}

@media screen and (max-width: 46.1875em) {
    .myCourseItem .cancelNet {
        justify-content: flex-start;
    }
    .myCourseItem {
        align-items: center;
        justify-items: center;
        /* width: 300px; */
        max-width: 300px;
    }
    .courseSearchResult {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .navFilter {
        display: none;
    }
    .navFilterRes {
        display: block;
    }
}

/* Tablet */
@media screen and (min-width: 46.25em) and (max-width: 63.9375em) {
    .myCourseItem .cancelNet {
        justify-content: flex-start;
    }
}

/* Effect zoom  */
@keyframes zoomI {
    0% {
        transform: scale(1.5);
    }
    90% {
        transform: scale(0.5);
    }
    100% {
        transform: scale(1);
    }
}
