/* BackTop */
.backTop{
    position: fixed;
    z-index: 99;
    right: 20px;
    bottom:20px;
    height: 40px;
    width: 40px;
    color: #ffffff;
    line-height: 40px;
    border-radius: 8px;
    text-align: center;
    background-color: var(--colorGlobal);
    
}