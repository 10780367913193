.bg{
    background: url(../../Assets/Img/green-bg.png);
    background-size: cover;
}  
.bg-all{
    background: url(../../Assets/Img/bg-all.png);
    background-size: cover;
    height: 100vh;
}
.details{
    background: white;
    border-radius: 10px;
    box-shadow: rgba(34,144,115, 0.205)  2px 2px 2px ;
    margin-left: 5px;
}
.myCardHeader{
    background: none;
    border-bottom: none;
}
.menu-icon{
    display: none;
}
.unhide{
    display: none;
}
.table td,.table th{
    padding: 0.5rem;
}
.modal-body{
    padding-bottom: 0;
    padding-top: 10px;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

#sidebar {
    min-width: 80px;
    max-width: 80px;
    background: url(../../Assets/Img/green-bg.png);
    background-size: cover;
    color: #fff;
    transition: all 0.3s;
    border-radius: 10px 10px;
    padding:5px 10px;
    min-height: 90vh;
    text-align: center;
}
/* thead{
    background: url(../../Assets/Img/thead.png);
    background-size: cover;
    color: white;
} */
.dropdown ul{
    transform: translate3d(-100px, 51px, 0px) !important;
}

.set-height{
    height:50vh;
    width:35vw;
    overflow-y: scroll;
}

#sidebar ul li a i {
    display: block;
    font-size: 1.3em;
    margin-bottom: 5px;
    padding: 5px;
}

#btnThem{
    background-color:var(--colorGlobal);
    color:white
}
#sidebar ul li a {
    padding: 30px 0;
    font-size: 1em;
    display: block;
    transition: all 0.5s;
}
#sidebar ul li a:hover{
    /* color: #4caf50; */
    color:var(--colorGlobal);
    background: #fff;
    border-radius: 10px;
}
#sidebarCollapse.btn-light:hover{
    color:var(--colorGlobal);
    background: #fff;
    border-radius: 10px;
}

.item{
    padding:10px 10px
}


#sidebar ul li.active > a, a[aria-expanded="true"] {
    color: #4caf50;
    background:#fff ;
}


a[data-toggle="collapse"] {
    position: relative;
}



/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
#content {
    display: flex;
    padding: 20px 0;
    height: 100%;
    transition: all 0.3s;
}


/* MODAL CSS */
/* .formUser{
    width: 30rem;
} */
.formCourse{
    max-width: 50rem;
}

/* form Container */
form .con {
    display: -webkit-flex;
    display: flex;
  
    -webkit-justify-content: space-around;
    justify-content: space-around;
  
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  
      margin: 0 auto;
}

/* the header form form */
header {
    margin: 2% auto 10% auto;
    text-align: center;
}
/* Login title form form */
header h2 {
    font-size: 250%;
    font-family: 'Playfair Display', serif;
    color: #3e403f;
}
/*  A welcome message or an explanation of the login form */
header p {letter-spacing: 0.05em;}



/* //////////////////////////////////////////// */
/* //////////////////////////////////////////// */


.input-item {
    background: #fff;
    color: #333;
    padding: 14.5px 0px 15px 9px;
    border-radius: 5px 0px 0px 5px;
}



/* Show/hide password Font Icon */
#eye {
    background: #fff;
    color: #333;
  
    margin: 5.9px 0 0 0;
    margin-left: -20px;
    padding: 15px 9px 19px 0px;
    border-radius: 0px 5px 5px 0px;
  
    float: right;
    position: relative;
    right: 1%;
    top: -.2%;
    z-index: 5;
    
    cursor: pointer;
}
/* inputs form  */
input[class="form-input"]{
    width: 240px;
    height: 50px;
  
    margin-top: 2%;
    padding: 15px;
    
    font-size: 16px;
    font-family: 'Abel', sans-serif;
    color: #5E6472;
  
    outline: none;
    border: none;
  
    border-radius: 0px 5px 5px 0px;
    transition: 0.2s linear;
    
}
input[id="txt-input"] {width: 250px;}
/* focus  */
input:focus {
    transform: translateX(-2px);
    border-radius: 5px;
}


/* Submits */
.submits {
    width: 48%;
    float: left;
    margin-left: 2%;
}

/*       Forgot Password button FAF3DD  */
.frgt-pass {background: transparent;}

/*     Sign Up button  */
.sign-up {background: #087f23;}

@keyframes ani9 {
    0% {
        transform: translateY(3px);
    }
    100% {
        transform: translateY(5px);
    }
}


/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

/* Mobie */
@media screen and (max-width: 767px) {
    h1,h2{
        font-size: 20px !important;
    }
    body{
        font-size: 12px;
    }
    .ghost{
        font-size: 12px
    }
    .loginBody button {
        padding: 10px 10px;
    }
    .loginInfo{
        display: none;
    }
    #container {
        width: 90vw;
        height: 80vh;
        max-width: 100%;
        max-height: 100%;
    }
#content{
    padding: 40px 0!important;
}
.pageLinkPages{
    margin-top: 20px;
}
.myTable,h5{
    font-size: 12px;
}
.myCardHeader{
    padding-bottom: 20px!important;
}
#sidebar,.mobile {
    display: none;
 }
.menu-icon{
    display: block;
}
.paginationPages li:first-of-type{
    display: none;
}
.paginationPages li:last-of-type{
    display: none;
}
.pageLinkPages{
    font-size: 10px;
    padding: 00.25rem 0.5rem;
}
.btn{
    font-size: 10px;
    padding: 00.25rem 0.5rem;
}
.details{
    padding: 10px 0;
    height: 90vh;
}
.form-control{
    font-size: 10px;
    padding: 00.3rem 0.25rem;
}
.table td{
    padding: 0.25rem 0.15rem;
}
.table th{
    padding: 0.4rem 0.15rem;
}
.card-header{
    padding:.25rem 1.25rem
}
#content{
    padding: 10px 0;
}
.hide{
    display: none;
}
.unhide{
    display: block;
}
/* #btnThem{
    margin-left: 1rem!important;
} */
.modal{
    width: 365px;
}
.modal-body{
    padding: 0;
}
#header-title{
    font-size: 18px;
}


}

@media screen and (width: 812px) {
    h1,h2{
        font-size: 20px;
    }
    input{
        padding:5px 10px!important
        
    }
    .myTable{
        font-size: 10px;
    }
   
    #sidebar,.mobile {
        display: none;
     }
    .menu-icon{
        display: block;
    }
    .paginationPages li:first-of-type{
        display: none;
    }
    .paginationPages li:last-of-type{
        display: none;
    }
    .pageLinkPages{
        font-size: 10px;
        padding: 00.25rem 0.5rem;
    }
    .btn{
        font-size: 10px;
        padding: 00.25rem 0.5rem;
    }
    .details{
        padding: 0;
        height: 90vh;
    }
    .form-control{
        font-size: 10px;
        padding: 00.3rem 0.25rem;
    }
    .table td{
        padding: 0.25rem 0.15rem;
    }
    .table th{
        padding: 0.25rem 0.15rem;
    }
    .card-header{
        padding:.25rem 1.25rem
    }
    #content{
        padding: 10px 0;
    }
    .hide{
        display: none;
    }
    .unhide{
        display: block;
    }
    .loginBody{
        margin: 5px 0;
        height: 95vh;
    }
    
    /* #btnThem{
        margin-left: 1rem!important;
    } */
    
}
/* Tablet */
@media screen and (min-width: 767px) and (max-width: 1025px) {
    #container {
        width: 90vw;
        height: 68vh;
        max-width: 100%;
        max-height: 100%;
    }
    #sidebar {
        display: none;
     }
     .hide{
        display: none;
    }
     .unhide{
        display: block;
    }
    .menu-icon{
        display: flex;
    }
    .myCardHeader{
        padding-bottom: 0px!important;
        padding-top: 0px!important;
    }
    
}
@media screen and (width:1024px) {
    .hide{
        display: none;
    }
    .unhide{
        display: block;
    }
}
