@font-face {
    font-family: "fontNumber";
    src: url(../../Assets/Fonts//Poppins/Poppins-Bold.ttf);
    font-style: normal;
}

.homePage {
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0 50px;
}

/* Slider Left */
.sloganBox {
    display: flex;
    position: relative;
    z-index: 10;
    justify-content: center;
    align-items: center;
}

.sloganContainer h1 {
    position: relative;
    z-index: 10px;
    font-weight: 600;
    font-size: 50px;
}

.sloganContainer h1:nth-of-type(1) {
}
.sloganContainer h1:nth-of-type(2) {
}
.sloganContainer h1:nth-of-type(3) {
    font-size: 65px;
    color: var(--colorGlobal);
    overflow: hidden;
    animation: aniText 5s steps(10) infinite;
}
.sloganContainer h1:nth-of-type(3) span {
    margin-left: 5px;
    font-size: 50px;
}
/* btnSlider */
.btnSlider {
    padding: 10px 20px;
}

/* Small box */
.smallBox {
    position: absolute;
    z-index: 1;
    height: 10%;
    width: 10%;
    background-image: radial-gradient(var(--colorGlobal) 10%, transparent 11%),
        radial-gradient(var(--colorGlobal) 10%, transparent 11%);
    background-size: 10px 10px;
}

.smallboxLeftTop {
    top: 10%;
    left: 10%;
    height: 70px;
    width: 20%;
}

.sloganBox .smallboxRightTop {
    top: 0;
    right: 0;
    transform: rotate(45deg);
    background-image: radial-gradient(var(--colorSPrimary) 10%, transparent 11%);
}

.sloganBox .smallboxRightBottom {
    bottom: 10%;
    left: 70%;
    height: 10%;
    width: 10%;
    background-image: radial-gradient(var(--colorTPrimary) 10%, transparent 11%);
}
.sloganBox .doubleBox {
    left: 74%;
}

/* Striangle-topRight */
.triangleTopRight {
    transform: rotate(-45deg);
    position: absolute;
    top: 5%;
    left: 10%;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 50px solid var(--colorSPrimary);
}
/* Slider img left */
.sliderPlaneImg {
    position: absolute;
    object-fit: cover;
    right: 20%;
    top: 20%;
    width: 20%;
    height: 20%;
}
/* Slider Right */
.sliderRight {
    position: relative;
}

.sliderImg {
}

.sliderMainImg {
    width: 100%;
}

.sliderSubImg {
    position: absolute;
    width: 15%;
    height: 15%;
}
.sliderCodeImg {
    left: 20%;
    top: 50%;
    animation: aniSider 4s infinite;
}

.sliderMesImg {
    top: 42%;
    right: 45%;
    transform-origin: bottom;
    animation: aniMessage 4s 3s linear infinite;
}

.sliderCloudImg {
    top: 0;
    left: 0;
    animation: aniCloud 5s linear infinite;
}

.sliderCloud2Img {
    top: 30%;
    left: 20%;
    animation: aniCloud 7s 2s linear infinite;
}

.sliderCloud3Img {
    top: 15%;
    left: 25%;
    animation: aniCloud 5s 2s linear infinite;
}

/* Info grid */
.infoCoureBox {
    padding: 50px 0;
}
.infoCourseHome {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    color: var(--colorWhite);
    gap: 20px;
}

/* info Large */
.infoItemHome {
    position: relative;
    overflow: hidden;
    padding: 20px;
}

.infoItemContent::before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: -160%;
    width: 50%;
    height: 100%;
    z-index: 2;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    transform: skewX(-17deg);
}

.infoItemHome:hover .infoItemContent::before {
    animation: shine 1.5s;
}

.infoItemHome li {
    padding: 5px;
}

.infoItemHome li i {
    padding-right: 10px;
}
.infoLargeItem {
    grid-row: 1 / span 2;
    background-color: var(--colorGlobal);
    background-image: url(../../Assets/Img/imgSlider/astronaut.png);
    background-position: right bottom;
    background-size: 50%;
    background-repeat: no-repeat;
}

.infoItemHome h3 {
    text-transform: uppercase;
    font-weight: 500;
}

.infoLargeItem p {
    font-weight: 300;
}
.infoLargeItem p span {
    font-weight: 700;
}
/* Info small */
.infoSmallItemA {
    background-color: var(--colorSPrimary);
    /* background: linear-gradient(90deg, rgba(246,186,52,1) 0%, rgba(65,178,148,1) 100%); */
}

.infoSmallItemB {
    background-color: #5c8295;
}
.infoSmallItemC {
    background-color: #63c0a8;
}

/* Course Home */
.coursesHome {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.coursesHome ul li {
    margin: 0 20px;
}
.cateCourse {
    display: flex;
}
.coursesHome ul li a {
    border-radius: 20px;
    padding: 10px 15px;
    transition: all 0.5s;
    /* background-color: #41b294;    */
}

.coursesHome ul li:hover a {
    background-color: #41b294;
    color: var(--colorWhite);
}

.coursesHome h6 a {
    font-weight: 600;
    color: var(--colorSPrimary);
}

/* coursesBotHome */
.coursesBotHome {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cardIcon {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cardIcon span {
    color: var(--colorTextCard);
}
.cardIcon span i {
    margin-right: 8px;
}

.iconOclock {
    color: #f5c002;
}

.iconCalendar {
    color: #f06f68;
}
.iconLevel {
    color: #65c9ff;
}
.cardFooter .iconTag {
    font-size: 18px;
    color: red;
    margin-left: 4px;
}

/* Sub Card */
.subCard {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    z-index: 99;
    top: -10%;
    right: -132%;
    padding: 20px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    transition: all 0.5s;
    width: 350px;
    min-width: 280px;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0px 0px 10px 4px rgb(0 0 0 / 10%);
}
.subCard::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -15px;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid var(--colorWhite);
}

.subCard img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.subCard .subCardHead,
.subCard h6,
.subCard p,
.subCard btn,
.subCard .cardIcon {
    padding: 10px 0;
}

.btnSubCard {
    padding: 15px;
    background-color: var(--colorGlobal);
}

/* card Sale */
.cardSale {
    position: absolute;
    top: 5px;
    left: -5px;
    width: 85px;
    height: 30px;
    padding: 5px 8px 4px;
    color: var(--colorWhite);
    background-color: red;
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
}

.cardSale::after {
    content: "";
    position: absolute;
    top: 0;
    right: -12px;
    width: 0;
    height: 0;
    border-width: 15px 12px 15px 0px;
    border-style: solid;
    border-color: red transparent red transparent;
}
.cardSale::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 0;
    height: 0;
    border-bottom: 10px solid transparent;
    border-right: 5px solid red;
    filter: brightness(0.8);
}

.boxNumberContainer {
    /* display: flex; */
    padding: 50px;
    /* align-items: center; */
    /* justify-content: space-between; */
    background-color: var(--colorbg);
}

.boxNumber {
    width: 100%;
    text-align: center;
}

.textNumber {
    font-family: fontNumber;
    color: var(--colorGlobal);
    font-size: 50px;
}

.textNumberTitle {
    font-weight: 500;
}

/* Img icon Box */
.imgIcon {
    width: 80px;
    height: 80px;
}
/* Effect hover Card */
.cardGlobal:hover .subCard {
    visibility: visible;
    opacity: 1;
}

/* Top Rate Instructor */
.instrutorContainer {
    padding: 0 50px;
}

.instrutorItem {
    padding: 10px;
    overflow: hidden;
    /* margin-bottom: 10px; */
}

.instrutorItem .row {
    flex-wrap: nowrap;
    transform: translateX(0);
    transition: ease-out 0.8s;
}

.instrutorContent {
    display: flex;
    height: 100%;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 15px;
    border-radius: 5px;
    border: none;
    transition: all 0.5s;
}

.instrutorContent:hover {
    box-shadow: 0px 0px 10px 6px rgb(0 0 0 / 13%);
}

.instrutorContent img {
    height: 80px;
    width: 80px;
    object-fit: cover;
    border-radius: 50%;
}

.instrutorContent h6 {
    padding-bottom: 5px;
}

.textReviewRole {
    padding: 3px 0;
}

.instrutorContent p i {
    color: var(--colorSPrimary);
}

.textReviewBot {
    font-size: 13px;
    color: var(--colorTextCard);
}

/* Slider */
.sliderDot {
    display: flex;
    justify-content: center;
    margin-top: 8px;
}
/* dot left */
.dotLeft {
    position: relative;
    padding-right: 20px;
}

.labelDotLeft {
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: var(--colorGlobal);
    cursor: pointer;
    border-radius: 50%;
    margin-right: 8px;
    opacity: 1;
}
.layDotLeft {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: var(--colorGlobal);
    opacity: 0.25;
    display: none;
}

/* dot right */
.dotRight {
    position: relative;
    margin-left: 10px;
}
.labelDotRight {
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: var(--colorGlobal);
    cursor: pointer;
    border-radius: 50%;
    opacity: 0.25;
}

.layDotRight {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: var(--colorGlobal);
    opacity: 0.25;
    display: block;
}
/* Effect Slider */
#sliderInstrutors:checked ~ .instrutorItem .row {
    transform: translateX(-16.66667%);
}
#sliderInstrutors:checked ~ .sliderDot .dotRight .labelDotRight {
    opacity: 1;
}
#sliderInstrutors:checked ~ .sliderDot .dotRight .layDotRight {
    display: none;
}
#sliderInstrutors:checked ~ .sliderDot .dotLeft .labelDotLeft {
    opacity: 0.25;
}
#sliderInstrutors:checked ~ .sliderDot .dotLeft .layDotLeft {
    display: block;
}
/* Review student */
.reviewStudent {
    position: relative;
    padding: 50px 0;
}
.textQoute {
    position: relative;
    line-height: 30px;
    font-size: 17px;
}

.reviewStudent blockquote q::before {
    position: absolute;
    top: 0;
    left: -6%;
    font-size: 50px;
    font-family: fantasy;
    color: var(--colorTPrimary);
}

.reviewImg {
    display: flex;
    position: relative;
    justify-content: center;
    height: 100%;
    align-items: center;
}

.reviewImg img {
    width: 200px;
    height: 200px;
    position: relative;
    z-index: 10;
}

.bgStudentReview {
    top: -10%;
    z-index: 1;
    height: 220px;
    width: 230px;
    position: absolute;
    background-color: var(--colorTPrimary);
    border-radius: 10px 100px 110px;
}

.quoteRight {
    padding: 10px;
}

.quoteRight p {
    color: var(--colorTPrimary);
}

.quoteRight span {
    color: var(--colorTextCard);
}

.review {
    padding: 20px 50px;
    position: relative;
    /* background-color: var(--colorbg); */
}

.reviewStudent .smallboxLeftTop {
    top: 0;
    left: 0;
    height: 70px;
    width: 10%;
}

.reviewStudent .triangleTopRight {
    top: 0;
    left: 0;
}

.reviewStudent .smallboxRightBottom {
    bottom: 0;
    right: 10%;
    height: 50px;
    background-image: radial-gradient(var(--colorSPrimary) 10%, transparent 11%);
}

.reviewStudent .smallboxRightTop {
    top: 0;
    right: 40%;
    height: 50px;
    width: 80px;
    background-image: radial-gradient(var(--colorGlobal) 10%, transparent 11%);
}
.reviewStudent .smallboxLeftBottom {
    border-radius: 50%;
    bottom: 0;
    left: 30%;
    height: 80px;
    width: 80px;
    background-image: radial-gradient(var(--colorGlobal) 10%, transparent 11%);
}

/* Card Mobie-Tablet */
.cardGlobalRes {
    display: flex;
    justify-content: center;
}

/* Mobie */
@media screen and (max-width: 46.1875em) {
    .sliderHome {
        flex-direction: column-reverse;
    }
    .sloganContainer h1 {
        font-size: 40px;
    }
    .sliderPlaneImg {
        position: absolute;
        object-fit: contain;
        right: 25%;
        top: 0%;
        width: 25%;
        height: 30%;
    }
    .infoCourseHome {
        grid-template-columns: none;
    }
    .subCard {
        display: none;
    }
    /* .cardGlobal{
        overflow: hidden;
    } */
    .instrutorContent p i {
        font-size: 13px;
    }

    /* Slider Mentor */
    #sliderInstrutors:checked ~ .instrutorItem .row {
        transform: translateX(-100%);
    }
    /* Review Student */
    .triangleTopRight {
        left: 15%;
    }
    .reviewStudent .smallboxLeftTop {
        width: 15%;
    }

    .reviewStudent .smallboxRightTop {
        right: 0;
    }

    .reviewStudent .smallboxRightBottom {
        display: none;
    }
    .reviewStudent .smallboxLeftBottom {
        left: auto;
        right: 0;
    }
}

/* Tablet */
@media screen and (min-width: 46.25em) and (max-width: 63.9375em) {
    .sliderHome {
        flex-direction: column-reverse;
    }
    .sliderPlaneImg {
        position: absolute;
        object-fit: contain;
        right: 25%;
        top: 0%;
        width: 25%;
        height: 30%;
    }
    .infoCourseHome {
        grid-template-columns: none;
    }
    .instrutorContent {
        padding: 5px;
    }
    .instrutorContent p i {
        font-size: 13px;
    }
    /* Slider Mentor */
    #sliderInstrutors:checked ~ .instrutorItem .row {
        transform: translateX(-50%);
    }
    /* Review student */
    .bgStudentReview {
        top: 10%;
    }
    .reviewStudent .smallboxLeftTop {
        width: 15%;
    }
}


/* Animation img slider */
@keyframes aniSider {
    0% {
        opacity: 0;
        transform: translateY(-5%);
    }
    50% {
        opacity: 1;
        transform: translateY(-10%);
    }
    100% {
        opacity: 0;
        transform: translateY(-15%);
    }
}

/* Animation cloud slider */
@keyframes aniCloud {
    0% {
        opacity: 1;
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        transform: translateX(100%);
    }
}

/* Animation message slider */
@keyframes aniMessage {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(30deg);
    }
    50% {
        transform: rotate(0deg);
    }
    75% {
        transform: rotate(-30deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

/* Animation text */
@keyframes aniText {
    0% {
        width: 0;
    }
    50% {
        width: 230px;
    }
}

/* Animation shine */
@keyframes shine {
    100% {
        left: 170%;
    }
}
