/* Footer */
.footerPages {
    padding: 10px;
    background-color: var(--colorbg);
}

.footerBody {
    padding: 10px 40px;
}

/* Icon footer */
.iconFooter {
    display: inline-block;
    margin: 0px 10px;
    width: 40px;
    height: 40px;
    color: #ffffff;
    background: var(--colorGlobal);
    border-radius: 50%;
    line-height: 40px;
    text-align: center;
    transition: all 0.5s;
}
.iconSize {
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin: 0 5px;
    transition: all 0.3s linear;
}

.iconSize:hover {
    box-shadow: 1px 2px 2px 1px #636363;
}
.iconFooter:hover {
    opacity: 1;
}

/* Menu footer */
.menuFooter li {
    padding: 5px 0;
    transition: all 0.5s;
    font-weight: 500;
}

/* Text footer */
.textFooterTitle {
    font-size: 25px;
    font-weight: 700;
}

.textCardTitle ul li:hover {
    transform: translateY(-2px);
    color: rgb(1, 135, 126);
    cursor: default;
}

.textCardTitle ul li i {
    font-size: 12px;
}

/* Form register Footer */
.formFooter {
    margin: 3px 0;
    outline: none;
    border: none;
    width: 300px;
    max-width: 100%;
    height: 40px;
    border: 2px solid var(--colorGlobal);
    border-radius: 8px;
    padding-left: 10px;
    background-color: rgb(245 245 245);
}

.extraFooter {
    display: flex;
    justify-content: space-between;
    padding: 5px 50px;
    border-top: 1px solid #d0d0d0;
}

/* Mobie */
@media screen and (max-width: 46.1875em) {
    .extraFooter{
        flex-direction: column;

    }
    .formFooter{
        width: 320px;
    }
}

/* Tablet */
@media screen and (min-width: 46.25em) and (max-width: 63.9375em) {
}
