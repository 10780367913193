.loginBody {
    background: url(../../Assets/Img/imgLogin/backgroundLogin2.png);
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 100%; */
    height: 100vh;
}

.loginBody h1 {
    /* font-size: 30px; */
    font-weight: bold;
    margin: 0;
}

.loginBody p {
    font-size: 14px;
    font-weight: 100;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 20px 0 30px;
}

/* span {
    font-size: 12px;
} */

.loginBody a {
    color: #36867b;
    font-size: 14px;
    text-decoration: none;
    margin: 15px 0;
}

#container {
    background: var(--colorWhite);
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.2), 0 10px 10px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
    width: 80vw;
    height: 83vh;
    max-width: 100%;
    max-height: 100%;
}

.form-container form {
    background: var(--colorWhite);
    display: flex;
    flex-direction: column;
    padding: 0 50px;
    /* height: 100%; */
    justify-content: center;
    align-items: center;
    text-align: center;
}

.formLoginUser{
    height: 100%;
}

.social-container {
    /* margin: 10px 0; */
    display: flex;
}

.social-container a {
    border: 1px solid #36867b;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    height: 40px;
    width: 40px;
}
.social-container a:hover {
    background: #36867b;
    color: white;
    transition: 0.3s;
}

.form-container input {
    background: #eee;
    border: none;
    padding: 10px 15px;
    margin: 5px 0;
    width: 100%;
}

.form-container input:focus {
    outline: 1px solid var(--colorGlobal);
}

.form-container select {
    background: #eee;
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 100%;
}

.form-container select:focus {
    outline: 1px solid var(--colorGlobal);
}

.loginBody button {
    border-radius: 20px;
    border: 1px solid #36867b;
    background: #36867b;
    color: var(--colorWhite);
    font-size: 12px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
}

.loginBody button:active {
    transform: scale(0.95);
}

.loginBody button:focus {
    outline: none;
}

.loginBody button.ghost {
    background: #36867b;
    border-color: var(--colorWhite);
}


.form-container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
}

.sign-in-container {
    left: 0;
    width: 50%;
    z-index: 2;
}

.sign-up-container {
    left: 0;
    width: 50%;
    z-index: 1;
    opacity: 0;
}

.overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
}

.overlay {
    /* background: #36867b; */
    background: url(../../Assets/Img/bg-all.png);
    background-size: cover;
    color: var(--colorWhite);
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateY(0);
    transition: transform 0.6s ease-in-out;
}

.overlay-panel {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
    height: 100%;
    width: 50%;
    text-align: center;
    transform: translateY(0);
    transition: transform 0.6s ease-in-out;
}

.overlay-right {
    right: 0;
    transform: translateX(0);
}

.overlay-left {
    transform: translateX(-20%);
}

/* Move signin to right */
.container.right-panel-active .sign-in-container {
    transform: translateX(100%);
}

/* Move overlay to left */
.container.right-panel-active .overlay-container {
    transform: translateX(-100%);
}

/* Bring signup over signin */
.container.right-panel-active .sign-up-container {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
}

/* Move overlay back to right */
.container.right-panel-active .overlay {
    transform: translateX(50%);
}

/* Bring back the text to center */
.container.right-panel-active .overlay-left {
    transform: translateX(0);
}

/* Same effect for right */
.container.right-panel-active .overlay-right {
    transform: translateX(20%);
}

/* Message */
.message {
    height: 22px;
}

/* errorMessage */
.errorMessage {
    text-align: left;
    color: rgba(240, 23, 23, 0.835);
    width: 100%;
    font-size: 15px
}

.container.right-panel-active .sign-up-container::-webkit-scrollbar{
    width: 0 !important;
    display: none;
}

/* Mobie */
@media screen and (max-width: 46.1875em) {
    .loginBody h1 {
        font-size: 30px;
    }
    .errorMessage{
        font-size: 12px;
    }
    .form-container form{
        padding: 0 10px;
    }
    .container.right-panel-active .sign-up-container{
        overflow-y: scroll;
        overflow-x: hidden;
    }
    
}

/* Tablet */
@media screen and (min-width: 46.25em) and (max-width: 63.9375em) {
    .loginBody h1 {
        font-size: 30px;
    }
    .container.right-panel-active .sign-up-container{
        overflow: auto;
    }
}
