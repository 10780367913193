.listCourseCategory{
    padding: 50px;
}

.courseCategoryBtn{
    display: inline-block;
    border-radius: 25px;
    padding: 12px 10px;
    cursor: default;
    border: 2px solid #e4e4e4;
    outline: none;
    transition: 0.5s linear;
    font-weight: 500;
}

.courseCategoryBtn i{
    color: var(--colorSPrimary);
}

.courseCategoryBtn:hover{
    border: 2px solid #113d3c;
    filter: brightness(110%);
    transform: scale(0.98);
}

/* Mobie */
@media screen and (max-width: 46.1875em) {
   
}

/* Tablet */
@media screen and (min-width: 46.25em) and (max-width: 63.9375em) {
   
}